<template>
  <v-container fluid>
    <v-card class="card--shadow">
      <v-row>
        <v-col class="ml-3" md="3">
          <label>Name</label>
          <v-text-field
            v-model.trim="name"
            type="text"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col class="ml-3" md="3">
          <label>Status</label>
          <v-select
            :items="[
              { value: 1, text: `Stop` },
              { value: 2, text: `On-going` },
              { value: 0, text: `` },
            ]"
            item-text="text"
            item-value="value"
            v-model="current_status"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col class="ml-3" md="3">
          <label>User</label>
          <v-select
            :items="[
              { value: 2, text: `App User` },
              { value: 1, text: `No App User` },
            ]"
            v-model="user_status"
            item-text="text"
            item-value="value"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col class="ml-3" md="3">
          <label>Reported</label>
          <v-select
            :items="[
              { value: 2, text: `Yes` },
              { value: 1, text: `No` },
            ]"
            v-model="reported_status"
            item-text="text"
            item-value="value"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col class="ml-3" md="2">
          <label>Month</label>
          <v-select
            :items="months"
            v-model="month"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col class="ml-3" md="2">
          <label>Year</label>
          <v-select
            :items="years"
            v-model="year"
            item-text="text"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <br />
          <v-btn
            @click="filterData"
            color="teal accent-4"
            depressed
            dark
            class="mt-1"
            style="margin-right: 5px"
            >Search</v-btn
          >
          <v-btn
            @click="setAllStatusNull"
            to="/dots"
            color="teal accent-4"
            depressed
            dark
            class="mt-1"
            style="margin-right: 5px"
            >view all</v-btn
          >
          <v-btn
            to="/new-dots"
            color="teal accent-4"
            depressed
            dark
            class="mt-1"
            >New Cross DOT</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <div v-show="loading">
      <h3>Loading...</h3>
    </div>
   
    <v-data-table
      v-show="!loading"
      :headers="headers"
      :items="datas"
      :items-per-page="itemsPerPage"
      @page-count="pageCount"
      :page.sync="page"
      item-key="id"
      :loading="loading"
      hide-default-footer
      hide-default-header
    >
      <template v-slot:header="{ props: { headers } }">
        <thead class="header">
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="header--text"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ index, item }">
        <tr>
          <td>
            {{ page > 1 ? (page - 1) * itemsPerPage + (index + 1) : index + 1 }}
          </td>
          <td>{{ item.patient && item.patient.name }}</td>
          <td>{{ item.patient.reg_unique_code }}</td>
          <td>{{ item.patient.sex == 1 ? "Male" : "Female" }}</td>
          <td>{{ item.dots_app_status == 1 ? "No" : "Yes" }}</td>
          <td>{{ item.volunteer && item.volunteer.name }}</td>
          <td>{{ item.reported_this_month == true ? "Yes" : "No" }}</td>
          <td>
            <span v-if="item.reported_this_month == true">{{
              item.reported_status
            }}</span>
          </td>
          <td>
            <v-btn
              @click="goToDetail(item)"
              icon
              color="orange darken-1"
              class="white--text"
              depressed
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center py-3">
      <v-pagination
        color="purple accent-4"
        :length="pageCount"
        v-model="page"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    dots_app_status: "",
    page: 0,
    itemsPerPage: 30,
    current_status: null,
    user_status: null,
    reported_status: null,
    month: null,
    year: null,
    name: "",
    item: {},
    headers: [
      { text: "No", value: "no" },
      { text: "Name", value: "name" },
      { text: "TB Code", value: "tb_code" },
      { text: "Sex", value: "sex" },
      { text: "Use App", value: "use_app" },
      { text: "Volunteer", value: "volunteer" },
      { text: "Reported", value: "reported" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions" },
    ],
  }),
  methods: {
    setAllStatusNull() {
      this.current_status = null;
      this.user_status = null;
    },
    goToDetail(item) {
      this.$router.push(`/dots-detail/${item.id}`);
    },

    filterData() {
      var query = {
        current_status: null,
        user_status: null,
        reported_status: null,
        name: null,
        month: null,
        year: null,
      };
      if (this.current_status) query.current_status = this.current_status;
      if (this.user_status) query.dots_app_status = this.user_status;
      if (this.reported_status) query.reported_status = this.reported_status;
      if (this.name) query.name = this.name;
      if (this.month && this.year) {
        query = { ...query, month: this.month, year: this.year };
      }

      this.$router.push({
        name: "dots",
        query: query,
      });
    },

    getData() {
      var params = {};
      if (this.$route.query.current_status) {
        params.current_status = this.$route.query.current_status;
      }
      if (this.$route.query.name) {
        params.name = this.$route.query.name;
      }
      if (this.$route.query.dots_app_status) {
        params.dots_app_status = this.$route.query.dots_app_status;
      }
      if (this.$route.query.reported_status) {
        params.reported_status = this.$route.query.reported_status;
      }
      if (this.$route.query.month && this.$route.query.year) {
        params = { ...params, month: this.month, year: this.year };
      }

      if (params != null) {
        this.$store.dispatch("DotsPatient/getByFilter", params);
      } else {
        this.$store.dispatch("DotsPatient/getAll", {
          page: this.$route.query.page || 1,
        });
      }
    },
  },
  created() {
    if (!this.$route.query.page) {
      this.page = 1;
    } else {
      this.page = parseInt(this.$route.query.page);
    }

    this.getData();
  },
  watch: {
    page(newValue) {
      this.$router.push("/dots?page=" + newValue);
    },
    $route() {
      this.getData();
    },
  },
  computed: {
    ...mapState("DotsPatient", ["datas", "pageCount"]),
    ...mapState("Loading", ["loading"]),
    ...mapState("Admin", ["admin"]),
    ...mapState("InitialData", ["months", "years"]),
  },
};
</script>

<style></style>
